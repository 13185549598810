import { render, staticRenderFns } from "./science_child3.vue?vue&type=template&id=44d51125&scoped=true"
import script from "./science_child3.vue?vue&type=script&lang=js"
export * from "./science_child3.vue?vue&type=script&lang=js"
import style0 from "./science_child3.vue?vue&type=style&index=0&id=44d51125&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44d51125",
  null
  
)

export default component.exports