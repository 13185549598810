<template>
    <div class="updata_box">
        <div class="personal_box">
            <div :class="isactive == 1 ? 'personala' : 'personal'" @click="personal(1)">个人信息</div>
            <div :class="isactive == 2 ? 'personala' : 'personal'" @click="personal(2)">作品管理</div>
            <div :class="isactive == 3 ? 'personala' : 'personal'" @click="personal(3)">退出登录</div>
        </div>
        <div style="width: 100%;height: 50px;"></div>
        <div class="updata_box_cenete" v-if="isactive === 1">
            <div class="updata_div">
                <div class="updata_div_toptext">个人信息</div>
                <img style="width: 100%;" src="../../../../static/wenchang/9.png" alt="">
                <div class="select1" style="padding-top: 108px;">
                    <div class="input2">
                        <div style="margin-right: 37px;margin-left: 71px;">
                            <img src="../../../../static/wenchang/姓名.png" alt="">
                        </div>
                        <div>
                            <el-input v-model="input" placeholder="请输入"></el-input>
                        </div>
                    </div>
                    <div class="input2">
                        <div style="margin-right: 42px">
                            <img src="../../../../static/wenchang/性别.png" alt="">
                        </div>
                        <div>
                            <el-select v-model="value" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="input2" style="padding-top: 84px;">
                    <div style="margin-right: 37px;margin-left: 28px;">
                        <img src="../../../../static/wenchang/出生日期.png" alt="">
                    </div>
                    <div>
                        <el-date-picker v-model="birthday" value-format="yyyy-MM-dd" type="date" placeholder="请选择出生日期">
                        </el-date-picker>
                    </div>
                </div>
                <div class="input2" style="padding-top: 84px;">
                    <div style="margin-right: 35px;margin-left: 30px;">
                        <img src="../../../../static/wenchang/所在单位.png" alt="">
                    </div>
                    <div style="width: 883px;"><el-input v-model="workUnit" placeholder="请输入"></el-input>
                    </div>
                </div>
                <div class="input2" style="padding-top: 84px;">
                    <div style="margin-right: 35px;margin-left: 30px;">
                        <img src="../../../../static/wenchang/电子邮箱.png" alt="">
                    </div>
                    <div style="width: 883px;"><el-input v-model="email" placeholder="请输入"></el-input>
                    </div>
                </div>
                <div class="input2" style="padding-top: 84px;">
                    <div style="margin-right: 35px;margin-left: 30px;">
                        <img src="../../../../static/wenchang/手机号码.png" alt="">
                    </div>
                    <div style="width: 883px;font-size: 24px;margin-bottom: 5px;">{{ fileList.phone }}
                    </div>
                </div>
                <div class="input2" style="padding-top: 84px;">
                    <div style="margin-right: 35px;margin-left: 30px;">
                        <img src="../../../../static/wenchang/通讯地址.png" alt="">
                    </div>
                    <div style="width: 883px;"><el-input v-model="address" placeholder="请输入"></el-input>
                    </div>
                </div>
                <div class="submit" @click="submit">
                    <img src="../../../../static/wenchang/提交.png" alt="">
                </div>
            </div>
        </div>
        <div class="updata_box_cenete" v-if="isactive === 2">
            <div class="updata_div">
                <div class="updata_div_toptext2">作品管理</div>
                <img style="width: 100%;" src="../../../../static/wenchang/9.png" alt="">
                <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"
                    v-if="fileList2.length == 0">
                    <img src="../../../../static/wenchang/wu.png" alt="">
                </div>
                <div class="zuopinglist" v-if="fileList2[0]">
                    <div class="zuopingitem" v-for="(item, index) in fileList2" @click="gotoxiangq(item)">
                        <div class="zuopingitem_img">
                            <el-image style="width: 100%; height: 100px" :src="item.cover" fit="contain"></el-image>
                        </div>
                        <div class="zuopingitem_txt">{{ item.artworkTitle }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 50px;"></div>
        <img class="updata_bottom" src="../../../../static/wenchang/4.png" alt="">
    </div>
</template>

<script>
import { userupdate, findOne, artworkselectList } from "../../../util/api"
export default {
    name: 'science_child2',

    data() {
        return {
            checked: false,
            options: [{
                value: 1,
                label: '男'
            }, {
                value: 0,
                label: '女'
            }],
            input: '',
            value: '',
            address: '',
            email: '',
            workUnit: '',
            birthday: '',
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            fileList: {},
            fileList2: [],
            isactive: 1,
        }
    },
    mounted() {

    },
    created() {
        this.getuserdata()
        this.artworkdata()
    },
    methods: {
        gotoxiangq(val) {
            // window.open('https://wenchuang-web.123bingo.cn/#/science_child7?id=' + val, '_blank');
            this.$router.push({ path: '/science_child2', query: { id: val.id } })
        },
        artworkdata() {
            artworkselectList(localStorage.getItem('phone')).then((res) => {
                this.fileList2 = res.data
            })
        },
        submit() {
            const ema = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!ema.test(this.email)) {
                this.$message({
                    message: '邮箱不正确！',
                    type: 'warning'
                });
                return
            }
            userupdate({
                id: localStorage.getItem('useinfo'),
                address: this.address,
                name: this.input,
                sex: this.value,
                email: this.email,
                workUnit: this.workUnit,
                birthday: this.birthday,
                phone: localStorage.getItem('phone')
            }).then((res) => {
                console.log(res);
                if (res.data.message) {
                    this.$message({
                        message: res.data.message,
                        type: 'warning'
                    });
                } else {
                    this.$message({
                        message: '提交成功！',
                        type: 'success'
                    });
                }
            })
        },
        getuserdata() {
            findOne({ phone: localStorage.getItem('phone') }).then((res) => {
                this.fileList = res.data;
                this.input = res.data.name;
                this.value = res.data.sex;
                this.birthday = res.data.birthday;
                this.email = res.data.email;
                this.workUnit = res.data.workUnit;
                this.address = res.data.address;
            })
        },
        personal(val) {
            if (val === 3) {
                localStorage.setItem('phone', '');
                this.currentphone = ''
                localStorage.setItem('useinfo', '');
                this.$router.push('/')
                location.reload();
            }
            this.isactive = val
        }
    }
}
</script>
<style scoped>
.submits {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 50px;
}

.submit {
    width: 100%;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 50px;
    cursor: pointer;
}

.updata_bottom {
    /* position: fixed;
    bottom: 0 */
    width: 100%;
    margin-bottom: -6px;
}

.updata_box_cenete {
    margin: 61px auto;
    width: 1061px;
    height: 1100px;
    background: #FFFFFF;
    border-radius: 30px;
}

.updata_box {
    background-image: url("../../../../static/wenchang/8.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.updata_div {
    position: relative;
    padding: 0 71px;
    padding-top: 51px;
    width: 100%;
    height: 100%;
}

.updata_div_toptext {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
}

.updata_div_toptext2 {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    color: #333333;
}

.input1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.input2 {
    width: 100%;
    display: flex;
    align-items: center;
}

.input3 {
    width: 100%;
    display: flex;
}

.select1 {
    display: flex;
    align-items: center;
}

.select2 {}

.input3uplodtxt {
    font-weight: 100;
    font-size: 14px;
    color: #F34242;
    margin-top: 20px;
    margin-left: 152px;
}

.el-upload__tip {
    font-size: 14px;
    color: #F34242;
}

.personal {
    width: 225px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 20px;
    background: #FFFFFF;
    color: #187AC2;
    border-radius: 10px;
    margin: 26px 0;
    cursor: pointer;
}

.personala {
    width: 225px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 20px;
    background: #187AC2;
    color: #FFFFFF;
    border-radius: 10px;
    margin: 26px 0;
    cursor: pointer;
}

.personal_box {
    position: absolute;
    top: 7%;
    left: 7%;
}

@media (max-width: 480px) {}

@media (max-width: 5000px) {
    .zuopinglist {
        margin-top: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }

    .zuopingitem {
        margin: 0 20px;
        cursor: pointer;
        text-align: center;
    }

    .zuopingitem_img {
        width: 150px;
        height: 100px;
    }

    .zuopingitem_txt {
        margin: 30px 0;
    }
}
</style>